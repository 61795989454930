window._ = require('lodash');
window.$ = require('jquery');
window.moment = require('moment');
window._ = require('lodash');
import owlCarousel from 'owl.carousel';
window._ = require('flowbite/dist/flowbite');

$(window).on('load', function () {
    $("#loading").animate({
      'opacity': '0'
    }, 600, function () {
      setTimeout(function () {
        $("#loading").css("visibility", "hidden").fadeOut();
      }, 300);
    });
  })

  $(function () {
    var header = $("#navbar");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 40) {
        header.addClass("sticky top-0 shadow");
      } else {
        header.removeClass("sticky top-0 shadow");
      }
    });
  });
/** carousel */
  $(document).ready(function ($) {
    "use strict";

    $('#logos-carousel').owlCarousel({
        loop: true,
        center: false,
        items: 5,
        margin: 0,
        autoplay: true,
        dots: false,
        nav: false,
        touchDrag: false,
        mouseDrag: false,
        smartSpeed: 450,
        slideTransition: 'linear',
        autoplayTimeout: 3000,
        autoplaySpeed: 3000,
        autoplayHoverPause: true,
        responsive: {
            480: {
                items: 2,
            },
            768: {
                items: 3
            },
            1170: {
                items: 5
            }
        }
    });
});



var imgEl = document.getElementsByTagName('img');
for (var i=0; i<imgEl.length; i++) {
    if(imgEl[i].getAttribute('data-src')) {
       imgEl[i].setAttribute('src',imgEl[i].getAttribute('data-src'));
       imgEl[i].removeAttribute('data-src'); //use only if you need to remove data-src attribute after setting src
    }
}

